import React, { memo, useImperativeHandle, forwardRef } from 'react'
import PropTypes from 'prop-types'
import DialogMU from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog = memo(forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        handleDialog: (val) => {
            handleDialog(val);
        }
    }));

    const [open, setOpen] = React.useState(false);
    const { title, contentText, children, actions, dialogProps } = props;

    const handleDialog = (value) => {
        setOpen(value);
    };

    return (
        <DialogMU
            open={open}
            TransitionComponent={Transition}
            keepMounted
            {...dialogProps}
        >
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>
                {contentText && <DialogContentText >{contentText}</DialogContentText>}
                {children}
            </DialogContent>
            <DialogActions>
                {actions}
            </DialogActions>
        </DialogMU>
    );
}));

Dialog.propTypes = {
    title: PropTypes.string,
    contentText: PropTypes.string,
    actions: PropTypes.node,
    dialogProps: PropTypes.object
}

Dialog.defaultProps = {
    title: '',
    contentText: '',
    dialogProps: {}
}

export default Dialog
