import React, { memo, useRef, useState } from "react";
import ReactJWPlayer from "react-jw-player";
import { Grid, Button, TextField } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Dialog from "../component/dailog/dialog";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import purple from '@material-ui/core/colors/purple';
import _ from "lodash";

const playerStyle = (theme => ({
  pathDialogue: {
    position: 'relative',
    width: '265px'
  },
  pathPoster: {
    width: '330px',
    position: 'relative',
    left: '-30px',
    top: '-25px'
  },
  pathMessage: {
    fontSize: '20px',
    width: '95%',
    marginTop: '-10px'
  },
  pathMessageBoom: {
    fontWeight: 'bold'
  },
  pathMessageBang: {
    fontWeight: 'bold'
  },
  pathSmallForm: {
    width: '265px'
  },
  pathFormMessage: {
    fontSize: '15px',
    width: '90%',
    display: 'block',
    marginBottom: '10px'
  },
  pathFormCompleteMessage: {
    fontSize: '25px',
    textAlign: 'center',
    color: purple[500]
  },
  pathTitle: {
    fontStyle: 'italic'
  },
  pathSubmessage: {
    margin: '10px 0',
    fontSize: '15px'
  },
  pathBottomButtons: {
  },
  pathInlineButton: {
    paddingLeft: '10px',
    position: 'relative',
    top: '5px'
  },
  pathListButton: {
    margin: '10px 10px 0 0',
    width: '100%'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit / 2,
    top: theme.spacing.unit / 2,
    color: theme.palette.grey[500],
    zIndex: 100
  },
  closeButtonWhite: {
    position: 'absolute',
    right: theme.spacing.unit / 2,
    top: theme.spacing.unit / 2,
    color: 'white',
    zIndex: 100
  },
  thumbUpButton: {
    float: 'right',
    color: 'white',
    background: 'black',
    borderRadius: '0 0 10px 10px',
    '-webkit-box-shadow': '0px 0px 20px 14px rgba(0,0,0,0.75)',
    '-moz-box-shadow': '0px 0px 20px 14px rgba(0,0,0,0.75)',
    'box-shadow': '0px 0px 20px 14px rgba(0,0,0,0.75)',
    '&:hover': {
      color: 'black'
    }
  }
}));

const videoContent = {
  url: 'https://movietrailers.apple.com/movies/sony_pictures/greyhound/greyhound-trailer-1_a720p.m4v',
  poster: 'https://trailers.apple.com/trailers/sony_pictures/greyhound/images/background_2x.jpg',
  thumbnail: 'https://trailers.apple.com/trailers/sony_pictures/greyhound/images/thumbnail_30486.jpg',
  title: 'Greyhound'
}

const Player = memo(props => {
  const [phoneNumber, setPhoneNumber] = useState(""),
        [phoneError, setPhoneError] = useState(true),
        [email, setEmail] = useState(""),
        [emailError, setEmailError] = useState(true),
        [phoneComplete, setPhoneComplete] = useState(false),
        [emailComplete, setEmailComplete] = useState(false),
        { classes } = props;

  const dialogRef = useRef(null),
        greenlightDialogRef = useRef(null),
        jwPlayer = useRef(null),
        rejectDialogRef = useRef(null),
        phoneDialogRef = useRef(null),
        emailDialogRef = useRef(null);

  const onCompletePlay = () => {
    if(document && document.fullscreen) {
      if(document.exitFullscreen) {
        document.exitFullscreen();
      } else if(document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if(document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if(document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    }
    greenlightDialogRef.current.handleDialog(true);
  },
  onClickIMAX = () => {
    greenlightDialogRef.current.handleDialog(false);
    dialogRef.current.handleDialog(true);
  },
  onClickLuxury = () => {
    greenlightDialogRef.current.handleDialog(false);
    dialogRef.current.handleDialog(true);
  },
  onClickDollar = () => {
    greenlightDialogRef.current.handleDialog(false);
    dialogRef.current.handleDialog(true);
  },
  onClickArthouse = () => {
    greenlightDialogRef.current.handleDialog(false);
    dialogRef.current.handleDialog(true);
  },
  onClickCalendarReminder = () => {
    emailDialogRef.current.handleDialog(true);
  },
  onClickCloseRef = () => {
    dialogRef.current.handleDialog(false);
  },
  onClickCloseGreenlightRef = () => {
    greenlightDialogRef.current.handleDialog(false);
  },
  onClickTextMessage = () => {
    phoneDialogRef.current.handleDialog(true);
  },
  onClickReject = () => {
    greenlightDialogRef.current.handleDialog(false);
    rejectDialogRef.current.handleDialog(true);

    setTimeout(function() {
      rejectDialogRef.current.handleDialog(false);
    }, 2000);
  },
  onClickPhoneSubmit = () => {
    setPhoneComplete(true);
    dialogRef.current.handleDialog(false);

    setTimeout(function() {
      phoneDialogRef.current.handleDialog(false);
    }, 1500);
  },
  onClickEmailSubmit = () => {
    setEmailComplete(true);
    dialogRef.current.handleDialog(false);  

    setTimeout(function() {
      emailDialogRef.current.handleDialog(false);
    }, 1500);
  },
  onClickClosePhoneDialog = () => {
    phoneDialogRef.current.handleDialog(false);
  },
  onClickCloseEmailDialog = () => {
    emailDialogRef.current.handleDialog(false);
  },
  onChangeEmail = e => {
    let email = e.target.value,
        format = /\S+@\S+\.\S+/,
        emailError = format.test(String(email).toLowerCase());

    setEmail(email.toLowerCase());
    setEmailError(!emailError);
  },
  onChangePhoneNumber = e => {
    let phoneNumber = e.target.value,
      formatedPhoneNo = phoneNumber.replace(/[^\d]/g, ""),
      phoneError = formatedPhoneNo.length < 10 ? true : false;
    if (formatedPhoneNo.length <= 10) {
      formatedPhoneNo = formatedPhoneNo.replace(
        /^(?:(\d{1,3}))?(?:(\d{1,3}))?(?:(\d{1,4}))?/,
        function (m, p1, p2, p3) {
          let parts = [p1, p2, p3],
            filteredParts = parts.filter(item => !_.isEmpty(item));
          return filteredParts.join("-");
        }
      );
      setPhoneNumber(formatedPhoneNo);
    }
    setPhoneError(phoneError);
  };
  
  return (
    <div className="player-root">
      <Grid container>
        <Grid item xs={12}>
          <ReactJWPlayer
            ref={jwPlayer}
            playerId="bNdmJxYp"
            onComplete={onCompletePlay}
            playerScript="https://cdn.jwplayer.com/libraries/bNdmJxYp.js"
            // playlist='https://cdn.jwplayer.com/v2/playlists/2ZX6mjj5'
            file={videoContent.url}
            image={videoContent.thumbnail}
          />
          <IconButton aria-label="ThumbUp" className={classes.thumbUpButton} onClick={onCompletePlay}>
            <ThumbUpIcon />
          </IconButton>
        </Grid>
        <Dialog
          ref={greenlightDialogRef}
        >
          <IconButton aria-label="Close" className={classes.closeButtonWhite} onClick={onClickCloseGreenlightRef}>
            <CloseIcon />
          </IconButton>
          <div className={classes.pathDialogue}>
            <img className={classes.pathPoster} src={videoContent.poster} />
            <div className={classes.pathMessage}>
              <div className={classes.pathMessageBang}>Would you "greenlight" <span className={classes.pathTitle}>{videoContent.title}</span>? Where would it play?</div>
              <div className={classes.pathSubmessage}>(Seize your moment! You have the power.)</div>
            </div>
            <Button className={classes.pathListButton} onClick={onClickLuxury} variant="contained" color="secondary">Luxury Cinema</Button>
            <Button className={classes.pathListButton} onClick={onClickIMAX} variant="contained" color="secondary">IMAX</Button>
            <Button className={classes.pathListButton} onClick={onClickArthouse} variant="contained" color="secondary">Art House</Button>
            <Button className={classes.pathListButton} onClick={onClickDollar} variant="contained" color="secondary">Dollar Cinema</Button>
            <Button className={classes.pathListButton} onClick={onClickReject} variant="contained" color="primary">Streaming only</Button>
            <Button className={classes.pathListButton} onClick={onClickReject} variant="contained" color="primary">Kill it now</Button>
          </div>
        </Dialog>
        <Dialog
          ref={rejectDialogRef}
        >
          <div className={classes.pathSmallForm}>
            <div className={classes.pathFormCompleteMessage}>
              <span className={classes.pathMessageBoom}>Fair enough!</span> Good feedback.
            </div>
          </div>
        </Dialog>
        <Dialog ref={dialogRef}>
          <IconButton aria-label="Close" className={classes.closeButtonWhite} onClick={onClickCloseRef}>
            <CloseIcon />
          </IconButton>
          <div className={classes.pathDialogue}>
            <img className={classes.pathPoster} src={videoContent.poster} />
            <div className={classes.pathMessage}>
              Thanks! We can get you advance tickets with your friends to <span className={classes.pathTitle}>{videoContent.title}</span> with <span className={classes.pathMessageBang}>no online fees</span>.
            </div>
            <div className={classes.pathSubmessage}>
              (It's pretty darn easy. We'll ping you when tickets go up, with a calendar reminder or via text.)
            </div>
            <div className={classes.pathBottomButtons}>
              <Button className={classes.pathListButton} onClick={onClickTextMessage} variant="contained" color="secondary">Text (SMS)</Button>
              <Button
                  className={classes.pathListButton}
                  onClick={onClickCalendarReminder}
                  variant="contained"
                  color="secondary"
                >Calendar</Button>
              </div>
          </div>
        </Dialog>
        <Dialog
          ref={emailDialogRef}
        >
          <IconButton aria-label="Close" className={classes.closeButton} onClick={onClickCloseEmailDialog}>
            <CloseIcon />
          </IconButton>
          { !emailComplete &&
            <div className={classes.pathSmallForm}>
              <div className={classes.pathFormMessage}>
                We'll use your e-mail address to put a reminder on your calendar.
              </div>
              <Grid container justify="center" alignItems="center" className={classes.pathSmallForm}>
                <Grid item>
                  <TextField
                    name="email"
                    value={email}
                    label="E-mail address"
                    onChange={onChangeEmail}
                  />
                </Grid>
                <Grid className={classes.pathInlineButton}>
                  <Button
                    onClick={onClickEmailSubmit}
                    variant="contained"
                    disabled={emailError}
                    color="secondary"
                  >Hook me up!</Button>
                </Grid>
              </Grid>
            </div>
          }
          { emailComplete &&
            <div className={classes.pathSmallForm}>
              <div className={classes.pathFormCompleteMessage}>
                <span className={classes.pathMessageBoom}>BOOM!</span> Stay tuned.
              </div>
            </div>
          }
        </Dialog>
        <Dialog
          ref={phoneDialogRef}
        >
          <IconButton aria-label="Close" className={classes.closeButton} onClick={onClickClosePhoneDialog}>
            <CloseIcon />
          </IconButton>
          { !phoneComplete &&
            <div className={classes.pathSmallForm}>
              <div className={classes.pathFormMessage}>
                We'll use your phone # to send a text (SMS) reminder.
              </div>
              <Grid container justify="center" alignItems="center">
                <Grid item>
                  <TextField
                    name="phone"
                    value={phoneNumber}
                    label="Phone"
                    onChange={onChangePhoneNumber}
                  />
                </Grid>
                <Grid className={classes.pathInlineButton}>
                  <Button
                    onClick={onClickPhoneSubmit}
                    variant="contained"
                    disabled={phoneError}
                    color="secondary"
                  >Hook me up</Button>
                </Grid>
              </Grid>
            </div>
          }
          { phoneComplete &&
            <div className={classes.pathSmallForm}>
              <div className={classes.pathFormCompleteMessage}>
                <span className={classes.pathMessageBoom}>BOOM!</span> Stay tuned.
              </div>
            </div>
          }
        </Dialog>
      </Grid>
    </div>
  );
});

export default withStyles(playerStyle)(Player);