import React from "react";
import "./App.css";
import Player from "./views/player";

function App() {
  return (
    <div className="App">
      <Player />
    </div>
  );
}

export default App;
